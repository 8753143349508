@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Lato";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** Include Lato font**/
@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Regular.ttf") format("truetype"),
    url("./assets/fonts/Lato/Lato-Italic.ttf") format("truetype"),
    url("./assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/Lato/Lato-Black.ttf") format("truetype"),
    url("./assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}

/** Include Impact font **/
@font-face {
  font-family: "Impact";
  src: local("Impact"),
    url("./assets/fonts/Impact/impact.ttf") format("truetype");
}

/** Loading spinner **/
.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 10px solid rgba(116, 134, 156, 0.3);
  border-radius: 50%;
  border-top-color: #80c801;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/** Skeleton Loading effect **/
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.arrow.right {
  transform: rotate(45deg);
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.disabled {
  pointer-events: none;
}

.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.disabled:hover {
  background-color: transparent;
  cursor: default;
}
